.barcode-scanner {
    /*display: none;*/
}

.dce-sel-camera {
    display: none !important;
}

.dce-sel-resolution {
    display: none !important;
}

.dbr-msg-poweredby {
    display: none !important;
}

.dce-video-container {
    background-color: #060706;
    border: 1px solid #060706;
}

div.scanner {
    width: 300.4px;
    height: 589.2px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 4px #ffffff;
    z-index: -101;
}

#video {
    min-width: 300.4px;
    min-height: 589.2px;
    width: auto;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -100;
    transform: translate(-50%, -50%);
}

#div-ui-container {
    height: 100vh;
}

#scanner-confirmation {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #060706;
    height: 100%;
}

#scanned-vin {
    color: white;
    font-family: monospace;
    font-size: 2rem;
    padding: 3rem 0;
}

#vin-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 4rem;
    padding: 1rem;
}

#vin-buttons > div {
    border-radius: 100%;
    padding: 0;
    margin: 0;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
#vin-button-confirm {
    background-color: green;
}

#vin-button-reject {
    background-color: red;
}

#div-ui-close {
    position: absolute;
    top: 0;
    right: 0;
}

#div-ui-camcycle {
    display: none;
    position: absolute;
    top: 65px;
    right: 0;
}

.btn-circle {
    margin: 20px;
    background-color: white;
    border-radius: 100%;
    border: none;
    text-align: center;
    font-size: 20px !important;
    line-height: 1.42857;
    z-index: 1000;
}
