@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

body {
    font-family: Open Sans, sans-serif;
    background: #efefef;
}

h1, h2, h3 {
    font-weight: 700;
}

#root {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 100vh;
}
