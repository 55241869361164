/* Global Styles */

.header {
    padding: 10px 17px;
    background: #fff;
    border-bottom: 1px solid #dee2e6;
}

.header h1 {
    font-size: 20px;
    letter-spacing: 0.24px;
    color: #1c1c1c;
    margin: 0;
}

.importers-table {
    margin-top: 25px;
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.importers-table tbody {
    display: block;
}

.importers-table tr {
    display: block;
}

.importers-table tr::after {
    content: '';
    height: 1px;
    margin: 0 17px;
    background-color: #dee2e6;
    position: relative;
    display: block;
}

.importers-table tr:last-child::after {
    content: '';
    height: 0px;
    display: none;
}

.importers-table tr td {
    border-top: none;
    padding: 10px 17px;
    font-size: 17px;
}

.LogoutButton {
    border-radius: 6px;
    background-color: #234614 !important;

    font-family: OpenSans, sans-serif;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
