/* Search Bar */

.search-bar {
    background-color: #fff;
    padding: 16px;
    padding-top: 0px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.search-bar .search-field {
    border-radius: 10px;
    background-color: #efefef;
    border: 0px solid;
    min-width: 100%;
    height: 36px;
    background-image: url("../../images/search-gray.svg");
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 36px;
}

/* Search List */
.search-info-text {
    width: 261px;
    font-family: OpenSans, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.08px;
    text-align: center;
    color: #6d6d72;
}

.search-info-top {
    display: block;
    margin: 17px auto auto;
}

.search-info-bottom {
    display: block;
    margin: 370px auto auto;
}

#cardlist-wrapper {
    padding-top: 70px;
    padding-bottom: 70px;
}

.cardlist-result-title {
    padding: 8px 6px 5px 6px;
    color: #6d6d72;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.38;
}

ul.cardlist {
    padding-left: 0;
    list-style: none;
}

ul.cardlist li {

}

ul.cardlist li:after {
    content: '';
    height: 1px;
    background-color: #efefef;
    display: block;
    margin: 0 16px;
}

ul.cardlist li .carditem {
    background-color: #fff;
    padding: 8px;
    background-image: url("../../images/right-chevron-gray.png");
    background-repeat: no-repeat;
    background-position: center right 10px;
}

ul.cardlist li .carditem .cardimage {
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
    background-color: #efefef;
}

ul.cardlist li .carditem .cardcontent {
    display: inline;
    position: absolute;
    margin-left: 15px;
    font-size: 16px;
}

ul.cardlist li .carditem .cardcontent .cardtitle {
    display: block;
    color: #000;
}

ul.cardlist li .carditem .cardcontent .cardsubtitle {
    display: block;
    color: #707070;
}

ul.cardlist li .carditem .cardcontent .cardlinktext {
    display: block;
    font-size: 14px;
    color: #000;
}
