/*Footer Bar */

.footer-bar {
    background-color: #f9f9f9;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-bar .footer-bar-inner {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;
    text-align: center;
}

.footer-bar .footer-bar-inner .footer-bar-block {
    width: 50%;
    display: inline-block;
    font-size: 14px;
    line-height: 1.14;
    color: #234614;
    font-weight: bold;
}

.footer-bar .footer-bar-inner .footer-bar-block .block-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-top: 4px;
}

.footer-bar .footer-bar-inner .footer-bar-block .block-content {
    display: inline-block;
    text-align: left;
}
