.show-header {
    background-color: #ffffff;
    padding: 8px 9px 17px;
    color: #0f0f0f;
    font-size: 16px;
    position: fixed;
    width: 100%;
}

.show-header a {
    color: #234614;
}

.show-header .show-backlink {
    position: absolute;
    left: 0;
    padding-left: 9px;
}

.show-header .show-backlink a {
    background-image: url("../../images/left-arrow-chevron-green.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;
}

.show-header .show-title {
    display: block;
    text-align: center;
}

.shipment-topper {
    text-align: center;
    padding: 65px 22px 22px;
}

.shipment-details {
    background-color: #ffffff;
    padding: 16px;
    font-size: 18px;
}

.shipment-details .heading {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    display: block;
}

.shipment-details .data-title {
    color: #707070;
}