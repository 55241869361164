.show-header {
    background-color: #ffffff;
    padding: 8px 9px 8px;
    color: #0f0f0f;
    font-size: 16px;
    position: fixed;
    width: 100%;
}

.show-header a {
    color: #234614;
}

.show-header .show-backlink {
    position: absolute;
    left: 0;
    padding-left: 9px;
}

.show-header .show-backlink a {
    background-image: url("../../images/left-arrow-chevron-green.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;
}

.show-header .show-title {
    display: block;
    text-align: center;
}

.shipment-topper {
    text-align: center;
    padding: 65px 22px 22px;
}

.shipment-details {
    background-color: #ffffff;
    padding: 16px;
    font-size: 18px;
}

.shipment-details .heading {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    display: block;
}

.shipment-details .data-title {
    color: #707070;
}

.shipment-details .edit-link {
    position: absolute;
    top: 21px;
    right: 16px;
    font-size: 17px;
    color: #234614 !important;
    background-image: url("../../images/edit.png");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 25px;
    line-height: 1;
}

.shipment-details .divider {
    background-color: #efefef;
    height: 1px;
    width: 40px;
    display: block;
    margin-bottom: 15px;
}

.shipment-details .no-images {
    color: #ba2323;
    font-weight: 600;
    margin-left: 15px;
}

.shipment-details .has-images {
    color: #1b9305;
    font-weight: 600;
    margin-left: 15px;
}

.shipment-details .button {
    width: 100%;
    display: block;
    text-align: center;
    background-color: #234614;
    color: #ffffff !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 12px;
}

.shipment-details .button img {
    margin-right: 12px;
    margin-top: -3px;
}

.image-gallery {
    display: table;
    padding: 10px 0;
}

.image-gallery .gallery-image-wrapper {
    float: left;
    width: 33.33%;
    max-height: 135px;
    overflow: hidden;
    padding: 10px 5px;
}

.image-gallery .gallery-image-wrapper:after {
    content: "";
    display: table;
    clear: both;
}

.image-gallery .gallery-image {
    width: 100%;
}
