@media all and (min-width: 480px) {
    .Login {
        padding: 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

@media all {
    .HeroImageHeader {
        width: 100%;
        height: 159px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 15px;
    }
}

@media (min-width: 1024px) {
    .HeroImageHeader {
        height: 350px;
        margin-bottom: 15px;
    }
}

.BayLogo {
    display: block;
    margin: 15px auto;
}

.Importer-Login {
    display: block;
    margin: 15px auto auto;
    font-family: OpenSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.TIERS-RIC {
    display: block;
    margin: auto auto 16px;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.FormGroup {
    display: block;
    margin: 16px auto;
    width: 339px;
}

.FormLabel {
    margin-bottom: 4px;
    margin-left: 8px;
}

.FormGroupRemember {
    margin-top: 25px;
    margin-bottom: 25px;
}

.RememberMe {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.SubmitButton {
    padding: 0;
    width: 155px;
    height: 42px;
    margin: 25px auto auto;
    border-radius: 6px;
    background-color: #234614 !important;

    font-family: OpenSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.paps-status-btn {
    background-color: transparent !important;
    border: 2px solid black !important;
}
.paps-btn {
    color: #000;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    font-size: 14px;
}
